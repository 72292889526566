/* src/App.css */
.App {
  /* text-align: center; */
  font-family: "Poppins", Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  margin: 0;
  padding: 0;
}

.App-header {
  background-color: #f0f0f0;
  padding: 20px;
  color: #333;
  border-bottom: 1px solid #ddd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.project-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0;
}

.project {
  border: 1px solid #ddd;
  margin: 10px;
  padding: 15px;
  width: 200px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.project:hover {
  transform: scale(1.05);
}

.project img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

label {
  text-align: left !important;
  display: block;
  margin-bottom: 8px;
  color: #555;
}

.video-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; /* Ensure the video is behind the content */
}

.overlay-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.content {
  padding: 2rem;
}

header {
  transition: background-color 0.3s ease;
}

.header-bg-white {
  background-color: #f0f0f0 !important;
  border-bottom: 2px solid #ddd;
}

button {
  cursor: pointer;
}

button.inline-flex {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 12px 24px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

button.inline-flex:hover {
  filter: brightness(90%);
}

button.bg-indigo-500 {
  background-color: #4f46e5;
  color: white;
  border: none;
}

button.bg-gray-100 {
  background-color: #f3f4f6;
  color: #333;
  border: none;
}

button.bg-gray-100:hover {
  background-color: #e5e7eb;
}

button.bg-indigo-600 {
  background-color: #4338ca;
}

@media(max-width: 768px){
  .custom-section{
    padding-top: 50px;
  }
}

.custom-section {
  min-height: calc(100vh - 110px);
}
